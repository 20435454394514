const routes = [
  {
    path: "",
    name: "overview",
    component: () =>
      import(
        /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/Dashboard.vue"
      ),
    props: true,
  },
  {
    path: "/help",
    name: "help",
    component: () =>
      import(
        /* webpackChunkName: "Dashboard" */ "@/view/dashboard/Help.vue"
      ),
    props: true,
  },
   
  // Masters

  {
    path: "/masters",
    name: "masters",
    component: () =>
      import(
        /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/masters/index.vue"
      ),
    children: [
      {
        path: "/dup_contact",
        name: "dup_contact",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/masters/XeroContact.vue"
          ),
        props: true,
      },

      {
        path: "/unuse_chart",
        name: "unuse_chart",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/masters/XeroUnusechart.vue"
          ),
        props: true,
      },

      {
        path: "/repeat_sinvoice",
        name: "repeat_sinvoice",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/masters/XeroSalesInvoice.vue"
          ),
        props: true,
      },
      {
        path: "/repeat_pinvoice",
        name: "repeat_pinvoice",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/masters/XeroPurchaseInvoice.vue"
          ),
        props: true,
      },
      {
        path: "/journal",
        name: "xero_journal",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/masters/XeroJournal.vue"
          ),
        props: true,
      },
    ],
  },

  // Balance Sheet
  {
    path: "/balance",
    name: "balance",
    component: () =>
      import(
        /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/balance/index.vue"
      ),
    children: [
      {
        path: "/xero_unreconciled",
        name: "xero_unreconciled",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/balance/XeroUnreconciled.vue"
          ),
        props: true,
      },

      {
        path: "/xero_capitalizeinvoice",
        name: "xero_capitalizeinvoice",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/balance/XeroCaptalizeinvoice.vue"
          ),
        props: true,
      },

      {
        path: "/xero_balancesheet",
        name: "xero_balancesheet",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/balance/XeroBalancesheetacc.vue"
          ),
        props: true,
      },

      {
        path: "/xero_interestbooked",
        name: "xero_interestbooked",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/balance/XeroInterestbooked.vue"
          ),
        props: true,
      },

      {
        path: "/xero_manualbank",
        name: "xero_manualbank",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/balance/XeroManualbanktras.vue"
          ),
        props: true,
      },
    ],
  },

  //Nominal
  {
    path: "/nominal",
    name: "nominal",
    component: () =>
      import(
        /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/nominal/index.vue"
      ),
    children: [
      {
        path: "/xero_dupinvoices",
        name: "xero_dupinvoices",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/nominal/XeroInvoice.vue"
          ),
        props: true,
      },

      {
        path: "/xero_vat",
        name: "xero_vat",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/nominal/XeroVat.vue"
          ),
        props: true,
      },

      {
        path: "/xero_nominal",
        name: "xero_nominal",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/nominal/Xeronominal.vue"
          ),
        props: true,
      },

      {
        path: "/xero_nominalvat",
        name: "xero_nominalvat",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/nominal/XeroNominalvat.vue"
          ),
        props: true,
      },

      {
        path: "/xero_transnominal",
        name: "xero_transnominal",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/nominal/XeroTransnominal.vue"
          ),
        props: true,
      },
      {
        path: "/xero_supplier",
        name: "xero_supplier",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/nominal/XeroSupplier.vue"
          ),
        props: true,
      },
      {
        path: "/xero_vatasset",
        name: "xero_vatasset",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/nominal/XeroVatasset.vue"
          ),
        props: true,
      },

      {
        path: "/xero_vatjournal",
        name: "xero_vatjournal",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/nominal/XeroVatjournal.vue"
          ),
        props: true,
      },
      
      {
        path: "/xero_pivotanalysis",
        name: "xero_pivotanalysis",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/nominal/XeroPivotAnalysis.vue"
          ),
        props: true,
      },

      {
        path: "/xero_lateentry",
        name: "xero_lateentry",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/nominal/XeroLateentries.vue"
          ),
        props: true,
      },

      {
        path: "/xero_trackingcat",
        name: "xero_trackingcat",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/nominal/XeroTrackingCat.vue"
          ),
        props: true,
      },
    ],
  },

  // AP/AR
  {
    path: "/ap_ar",
    name: "ap_ar",
    component: () =>
      import(
        /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/ap/index.vue"
      ),
    children: [
      {
        path: "/xero_pay",
        name: "xero_pay",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/ap/XeroPay.vue"
          ),
        props: true,
      },

      {
        path: "/xero_debit",
        name: "xero_debit",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/ap/XeroDebit.vue"
          ),
        props: true,
      },

      {
        path: "/xero_supplierinvoices",
        name: "xero_supplierinvoices",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/ap/Xerosupplierinvoices.vue"
          ),
        props: true,
      },

      {
        path: "/partial_pay",
        name: "partial_pay",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/ap/XeroPartialPayments.vue"
          ),
        props: true,
      },

      {
        path: "/receipts_allocation",
        name: "receipts_allocation",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/ap/XeroReceiptsAllocation.vue"
          ),
        props: true,
      },

      {
        path: "/xero_PIdraft",
        name: "xero_PIdraft",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/ap/XeroPIDraft.vue"
          ),
        props: true,
      },

      {
        path: "/xero_invoicematch",
        name: "xero_invoicematch",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/ap/XeroInvoicematch.vue"
          ),
        props: true,
      },

      {
        path: "/xero_dabtorinvoices",
        name: "xero_dabtorinvoices",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/ap/Xerodebtorinvoices.vue"
          ),
        props: true,
      },
      {
        path: "/xero_payment_expensed",
        name: "xero_payment_expensed",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/ap/Xeropaymentexpensed.vue"
          ),
        props: true,
      },
      {
        path: "/xero_receipt",
        name: "xero_receipt",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/ap/XeroReceipt.vue"
          ),
        props: true,
      },

      {
        path: "/xero_SIdraft",
        name: "xero_SIdraft",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/ap/XeroSIDraft.vue"
          ),
        props: true,
      },
      ],
    },
      // Dynamic Points
      {
    path: "/dynamic",
    name: "dynamic",
    component: () =>
      import(
        /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/dynamic/index.vue"
      ),
    children: [
      {
        path: "/xero_Fainvoices",
        name: "xero_Fainvoices",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/dynamic/XeroFainvoices.vue"
          ),
        props: true,
      },
      {
        path: "/xero_Entertainment",
        name: "xero_Entertainment",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/dynamic/EntertainmentExpenses.vue"
          ),
        props: true,
      },
      {
        path: "/supplier-invoice",
        name: "supplier-invoice",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/dynamic/SupplierInvoice.vue"
          ),
        props: true,
      },

      {
        path: "/value-invoice",
        name: "value-invoice",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/dynamic/ValueInvoice.vue"
          ),
        props: true,
      },
      {
        path: "/xero_accrual",
        name: "xero_accrual",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/dynamic/PotentialAccrual.vue"
          ),
        props: true,
      },
      {
        path: "/xero_prepayments",
        name: "xero_prepayments",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/dynamic/PotentialPrepayments.vue"
          ),
        props: true,
      },
      {
        path: "/da_invoices",
        name: "da_invoices",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/dynamic/XeroDainvoices.vue"
          ),
        props: true,
      },
      {
        path: "/vat_late",
        name: "vat_late",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/dynamic/VatLateclaims.vue"
          ),
        props: true,
      },
    ],
    },

      
      
    
  
];

export default routes;
