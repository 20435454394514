export default [ 
  {
    path: "",
    name: "dashboard",
    component: () =>
      import(/* webpackChunkName: "Dashboard" */ "@/view/dashboard/Home.vue"),
    props: true,
  },
  {
    path: "/profile",
    name: "profile",
    component: () =>
      import(/* webpackChunkName: "Dashboard" */ "@/view/dashboard/user/Profile.vue"),
    props: true,
  },
  {
    path: "/savetenant/:id",
    name: "savetenant",
    component: () =>
      import(/* webpackChunkName: "Dashboard" */ "@/view/dashboard/Home.vue"),
  }, 
  {
    path: "users",
    name: "users",
    component: () =>
      import(
        /* webpackChunkName: "Dashboard" */ "@/view/dashboard/admin/usermaster/UserMaster.vue"
      ),
    props: true,
  },
  {
    path: "roles",
    name: "roles",
    component: () =>
      import(
        /* webpackChunkName: "Dashboard" */ "@/view/dashboard/admin/rolemaster/RoleMaster.vue"
      ),
    props: true,
  },

  

  
//   // Masters

//   {
//     path: "overview/dup_contact",
//     name: "overview/dup_contact",
//     component: () =>
//       import(
//         /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/masters/XeroContact.vue"
//       ),
//     props: true,
//   },

//   {
//     path: "overview/unuse_chart",
//     name: "overview/unuse_chart",
//     component: () =>
//       import(
//         /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/masters/XeroUnusechart.vue"
//       ),
//     props: true,
//   },

//   {
//     path: "overview/repeat_invoice",
//     name: "overview/repeat_invoice",
//     component: () =>
//       import(
//         /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/masters/XeroRepeatinvoice.vue"
//       ),
//     props: true,
//   },

// // Balance Sheet 

//   {
//     path: "overview/xero_unreconciled",
//     name: "overview/xero_unreconciled",
//     component: () =>
//       import(
//         /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/balance/XeroUnreconciled.vue"
//       ),
//     props: true,
//   },

//   {
//     path: "overview/xero_capitalizeinvoice",
//     name: "overview/xero_capitalizeinvoice",
//     component: () =>
//       import(
//         /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/balance/XeroCaptalizeinvoice.vue"
//       ),
//     props: true,
//   },

//   {
//     path: "overview/xero_balancesheet",
//     name: "overview/xero_balancesheet",
//     component: () =>
//       import(
//         /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/balance/XeroBalancesheetacc.vue"
//       ),
//     props: true,
//   },

  
//   {
//     path: "overview/xero_interestbooked",
//     name: "overview/xero_interestbooked",
//     component: () =>
//       import(
//         /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/balance/XeroInterestbooked.vue"
//       ),
//     props: true,
//   },

//   {
//     path: "overview/xero_manualbank",
//     name: "overview/xero_manualbank",
//     component: () =>
//       import(
//         /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/balance/XeroManualbanktras.vue"
//       ),
//     props: true,
//   },

//   //Nominal 

//   {
//     path: "overview/xero_invoices",
//     name: "overview/xero_invoices",
//     component: () =>
//       import(
//         /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/nominal/XeroInvoice.vue"
//       ),
//     props: true,
//   },
  
//   {
//     path: "overview/xero_nominal",
//     name: "overview/xero_nominal",
//     component: () =>
//       import(
//         /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/nominal/Xeronominal.vue"
//       ),
//     props: true,
//   },

//   {
//     path: "overview/xero_vat",
//     name: "overview/xero_vat",
//     component: () =>
//       import(
//         /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/nominal/XeroVat.vue"
//       ),
//     props: true,
//   },

//   {
//     path: "overview/xero_nominalvat",
//     name: "overview/xero_nominalvat",
//     component: () =>
//       import(
//         /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/nominal/XeroNominalvat.vue"
//       ),
//     props: true,
//   },

//   {
//     path: "overview/xero_transnominal",
//     name: "overview/xero_transnominal",
//     component: () =>
//       import(
//         /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/nominal/XeroTransnominal.vue"
//       ),
//     props: true,
//   },

//   {
//     path: "overview/xero_vatasset",
//     name: "overview/xero_vatasset",
//     component: () =>
//       import(
//         /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/nominal/XeroVatasset.vue"
//       ),
//     props: true,
//   },

//   {
//     path: "overview/xero_vatjournal",
//     name: "overview/xero_vatjournal",
//     component: () =>
//       import(
//         /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/nominal/XeroVatjournal.vue"
//       ),
//     props: true,
//   },

//   {
//     path: "overview/xero_lateentry",
//     name: "overview/xero_lateentry",
//     component: () =>
//       import(
//         /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/nominal/XeroLateentries.vue"
//       ),
//     props: true,
//   },
// // AP/AR
// {
//   path: "overview/xero_pay",
//   name: "overview/xero_pay",
//   component: () =>
//     import(
//       /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/ap/XeroPay.vue"
//     ),
//   props: true,
// },

// {
//   path: "overview/xero_debit",
//   name: "overview/xero_debit",
//   component: () =>
//     import(
//       /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/ap/XeroDebit.vue"
//     ),
//   props: true,
// },

// {
//   path: "overview/xero_supplierinvoices",
//   name: "overview/xero_supplierinvoices",
//   component: () =>
//     import(
//       /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/ap/Xerosupplierinvoices.vue"
//     ),
//   props: true,
// },

// {
//   path: "overview/xero_partial",
//   name: "overview/xero_partial",
//   component: () =>
//     import(
//       /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/ap/Xeropartial.vue"
//     ),
//   props: true,
// },

// {
//   path: "overview/xero_PIdraft",
//   name: "overview/xero_PIdraft",
//   component: () =>
//     import(
//       /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/ap/XeroPIDraft.vue"
//     ),
//   props: true,
// },

// {
//   path: "overview/xero_invoicematch",
//   name: "overview/xero_invoicematch",
//   component: () =>
//     import(
//       /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/ap/XeroInvoicematch.vue"
//     ),
//   props: true,
// },




];
